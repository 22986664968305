import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { About } from './pages/info/About';
import { AppStateProvider } from './contexts/AppStateContext';
import BuyBids from './pages/BuyBids';
import ChangeEmail from './pages/account/ChangeEmail';
import ChangeUsername from './pages/account/ChangeUsername';
import Checkout from './pages/account/Checkout';
import Contact from './pages/info/Contact';
import Dashboard from './pages/Dashboard';
import ExchangeBox from './utils/ExchangeBox';
import FAQ from './pages/info/FAQ';
import GenericListing from './pages/GenericListing';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import Login from './pages/account/Login';
import Navigation from './components/navigation/Navigation';
import NavigationMobile from './components/navigation/NavigationMobile';
import NavigationMobileFooter from './components/navigation/NavigationMobileFooter';
import PListingEdit from './pages/portal/PListingEdit';
import PProductEdit from './pages/portal/PProductEdit';
import PUserDashboard from './pages/portal/PUserDashboard';
import Partners from './pages/Partners';
import PasswordReset from './pages/account/PasswordReset';
import PasswordResetActivate from './pages/account/PasswordResetActivate';
import PostReg from './pages/account/PostReg';
import PostVerify from './pages/account/PostVerify';
import Privacy from './pages/info/Privacy';
import { PurchaseSuccess } from './pages/PurchaseSuccess';
import React from 'react';
import Register from './pages/account/Register';
import RegisterAlternate from './pages/account/RegisterAlternate';
import Terms from './pages/Terms';
import Verify from './pages/account/Verify';

const App: React.FC = () => {

  return (
    <AppStateProvider>
      <BrowserRouter>
          <Navigation />
          <NavigationMobile />
          <ExchangeBox />
          <Routes >
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/checkout" element={<Checkout />} /> 
            <Route path="/contact" element={<Contact />} />  
            <Route path="/faq" element={<FAQ />} />          
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/registeralt" element={<RegisterAlternate/>} />
            <Route path="/forgot" element={<PasswordReset />} />
            <Route path="/resetpassword/:id" element={<PasswordResetActivate />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/username" element={<ChangeUsername />} />
            <Route path="/a/:id" element={<GenericListing />} />
            <Route path="/s/:id" element={<GenericListing />} />
            <Route path="/buybids" element={<BuyBids />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/postreg" element={<PostReg />} />
            <Route path="/postverify/:id" element={<PostVerify />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/changeUsername" element={<ChangeUsername />} />
            <Route path="/changeEmail" element={<ChangeEmail />} />
            <Route path="/purchaseSuccess" element={<PurchaseSuccess />} />
            <Route path="/portal" element={<PUserDashboard />} />
            <Route path="/portal/p/edit" element={<PProductEdit />} />
            <Route path="/portal/a/edit" element={<PListingEdit />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
          </Routes>
          <NavigationMobileFooter />
        </BrowserRouter>

    </AppStateProvider>
  );
};

export default App;
