import { Field, Form, Formik } from "formik";
import { MDBCol, MDBContainer, MDBIcon, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";

import AuthService from "../services/auth.service";
import { Banner } from "../components/banner/Banner";
import BidpackService from "../services/bidpack.service";
import { Card } from "react-bootstrap";
import Footer from "../components/navigation/Footer";
import FooterEnd from "../components/navigation/FooterEnd";
import HomeListing from "../components/listing/HomeListing";
import Important from "../components/Important";
import OtherService from "../services/other.service";
import React from "react";
import SocketContext from "../contexts/SocketContext";
import io from "socket.io-client";
import { render } from "@testing-library/react";
import { setegid } from "process";

const Partners: React.FC = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState('')
    const [submitted, setSubmitted] = useState<boolean>(false)

    interface FormValues {
        npName: string,
        npWeb: string,
        npContact: string,
        npEmail: string,
        npPhone: string
    }

    const initialValues : FormValues = {
        npName: "",
        npWeb: "",
        npContact: "",
        npEmail: "",
        npPhone: ""
    };

    const isValidEmail = (email: string) => {
        return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)
    }

    const handleRegister = async (formValue: { 
        npName: string,
        npWeb: string,
        npContact: string,
        npEmail: string,
        npPhone: string
    }) => {
        const { npName, npWeb, npContact, npEmail, npPhone } = formValue;
        console.log(npName, npWeb, npContact, npEmail, npPhone)
        if (npName && npContact && npEmail && isValidEmail(npEmail)) {
            const data = {"np_name": npName, "np_web": npWeb, "np_contact": npContact, "np_email": npEmail, "np_phone": npPhone};
            setLoading(true)
            try {
                await OtherService.submitInquiry(data)
                alert("Your response has been submitted! Please wait up to 24 hours for a response from nabify.")  
                setLoading(false)      
                setSubmitted(true)  
                setError('')      
            } catch (e) {
                setLoading(false)
            }

        } else {
            if (!npName) {
                setError("Please provide your organization's name.")
            } else if (!npContact) {
                setError('Please provide a contact name.')
            } else if (!npEmail) {
                setError('Please provide a valid e-mail.')
            } else if (isValidEmail(npEmail)) {
                setError('Please check the format of your e-mail address.')
            }

        }

    }

    let submissionView = (<></>)
    if (!submitted) {
        submissionView = (
            <button type="submit" className="btn btn-other" disabled={loading}>
                {loading && (
                <span style={{color: "white"}}>Please Wait...</span>
                )}
                {!loading && (
                <span>Enroll Now!</span>
                )}
        
            </button>
        )
    }

    return (
        <>
            <br />
            <br />
            <MDBContainer>
                <h1 className="text-center"> Our Partners </h1>
                <br />
                <MDBRow>
                    <MDBCol className="text-center" md={5}>
                        <img src="https://www.nabify.com/img/nabifylogo.png" height="100" alt="nabify logo" />
                    </MDBCol>
                    <MDBCol md={7}>
                        <br />
                        <h3>nabify</h3>
                        <p>Providing entertainment auctions for non-profits and charities around the United States. We make bidding fun while giving back to the community. Check out our <span className="btn-other-sm-tag" style={{cursor: 'pointer'}} onClick={()=>{navigate('/how-it-works')}} >How-It-Works</span> page to see how we do it.</p>
                    </MDBCol>
                </MDBRow>
                <br />
                <hr />
                <MDBRow>
                    <MDBCol md={6}>
                        <br />
                        <h3></h3>
                        <br />
                        <h3>Give Back & Get More</h3>
                        <br />
                        <p>Looking to partner your non-profit or charity? Get started now! </p>
                        <p>
                            NOTE: You will need to present the following below after account reservation.
                            <br />
                            <MDBIcon size="1x" icon='check' color='success'/>{'  '}501(c)(3) Document for proof of documentation
                            <br />
                            <MDBIcon size="1x" icon='check' color='success'/>{'  '}Organization branding and identity
                        </p>
                    </MDBCol>
                    <MDBCol className="text-center" md={6}>
                        <Card >
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleRegister}
                            >
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="npName">Organization Name</label>
                                    <Field name="npName" type="text" className="form-control" maxLength={30}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="npEmail">Contact Email</label>
                                    <Field name="npEmail" type="text" className="form-control" maxLength={30}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="npContact">Name of Contact</label>
                                    <Field name="npContact" type="text" className="form-control" maxLength={50}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="npWeb">Organization Website (optional)</label>
                                    <Field name="npWeb" type="text" className="form-control" maxLength={30}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="npPhone">Contact Phone (optional)</label>
                                    <Field name="npPhone" type="text" className="form-control" maxLength={15}/>
                                </div>
                                <div>
                                    <br />
                                    <br />
                                    {error && <p className="animated fadeIn btn-other-sm-tag" style={{backgroundColor: "#C33"}}>{error}</p>}
                                </div>
                                <div className="form-group" style={{textAlign: 'center'}}>
                                    <br /> 
                                    {submissionView}
                                </div>
                            </Form>
                        </Formik>
                        </Card>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <br />
            <Footer />
        </>
    )
}

export default Partners