import TokenService from "./token.service";
import axios from "axios";

const prodConfig = {
  connectionURL: "https://api-base.nabify.com/api/",
  projectId: "nabify-webapp"
};

const devConfig = {
  connectionURL: "http://localhost:8000/api/",
  projectId: "nabify-test-dev"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

const instance = axios.create({
  baseURL: config.connectionURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const submitInquiry = (data) => {
    return instance.post("inquire/", data)
}

const OtherService = {
    submitInquiry
};

export default OtherService