import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit"
import { useEffect, useState } from "react"

import AuthService from "../services/auth.service"
import { Button } from "react-bootstrap"
import Footer from "../components/navigation/Footer"
import { useAppState } from "../contexts/AppStateContext"
import { useNavigate } from "react-router"

// https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png
// https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png
// https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png

const HowItWorks : React.FC = () => {
    
    const [status, setStatus] = useState('')
    const [message, setMessage] = useState('') 
    const [actionText, setActionText] = useState('')


    const appState = useAppState()
    const navigate = useNavigate()

    useEffect(() => {
        if (AuthService.getCurrentUser()) {
            if (appState.state.bids && appState.state.bids > 0) {
                setStatus('hasBids')
                setMessage('Start Bidding!')
                setActionText('')
            }
            else if (appState.state.bids && appState.state.bids < 1) {
                setStatus('noBids')
                setMessage('Out of bids?')
                setActionText('Buy Bids!')
            }
        } else {
            setStatus('loggedIn')
            setMessage('Ready to start giving?')
            setActionText('Get Started!')
        }
    }, [appState])

    const handleClick = () => {
        if (status == 'hasBids') {
            navigate('/buybids')
        }
        if (status == 'noBids') {
            navigate('/buybids')
            }
        if (status == 'loggedIn') {
            navigate('/login')
        }
    }


    return (
        <>
            <br />
            <br />
            <MDBContainer>
                <h1 className="text-center"> Bidding Guide </h1>
                <br />
                <h4>Curious on how to give and get back? This visual guide demonstrates how you can get started.</h4>
                <br />
                <MDBRow>
                    <MDBCol xs={12}>
                        <h3>Getting Started</h3>
                        <p>Most of our current auctions will use nabify bids. You can find how many bids you have by looking for the 
                            <img src="https://nabify.com/img/nabify_24.png" width="24" height="24" /> icon your computer or the bids indicator below the top navigation bar on Mobile.
                            Click or tap the indicator to open up Bid Packs.
                        </p>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={8}>
                        <h3>Bid Packs</h3>
                        <p>Most of our current auctions will use nabify bids. You can find how many bids you have by looking for the 
                            <img src="https://nabify.com/img/nabify_24.png" width="24" height="24" /> icon your computer or the bids indicator below the top navigation bar on Mobile.
                            Click or tap the indicator to open up Bid Packs.
                        </p>
                        <p>
                            Each bid pack comes with bids designated in the image. The higher the amount of bids you purchase, the less each bid will cost. The lowest bid pack starts at 60 cents per bid. 
                        </p>
                    </MDBCol>
                    <MDBCol md={4} className="text-center">
                        <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/ss_bidpack.png" height="240" />
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={4}>
                        <h3>How Bidding Works</h3>
                        <p>After acquiring bids, you can bid on any of the live nabify auctions on the website.</p>
                        <p>Click the Bid Now button can will add $0.01 to the counter and reset the timer in two different ways.</p>
                    </MDBCol>
                    <MDBCol md={8}>
                        <br />
                        <p>If the timer is over 10 seconds, the timer get 10 seconds and you will be the winner if no one else bids! If you bid under 10 seconds, the timer will reset.</p>
                        <h5>Most bidders will wait until the last 10 seconds to bid! We recommend that you wait until the last 10 seconds!</h5>
                        <img src="https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part1.png" height="240" />
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={4}>
                        <br />
                        <h3>Competition and Winning</h3>
                        <p>You may be experiencing a lot of competition. Before the timer resets, your bid will be put onto a stack of bids. The person who bids the closest to 0 seconds will be assigned the bidder to win! </p>
                        <p>Click on an auction from the Home page to view the bidding details.</p>
                    </MDBCol>
                    <MDBCol md={4}>
                        <br />
                        <img className="d-hidden" src="https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part2.png" height="320" />
                    </MDBCol>
                    <MDBCol md={4}>
                        <br />
                        <br />
                        <ul className="bid_history">
                            <li>You - $0.15 - 4:20:01</li>
                            <li>Bidder 31 - $0.14 - 4:20:01</li>
                            <li>Bidder 12 - $0.13 - 4:20:01</li>
                            <li>Bidder 14 - $0.12 - 4:20:01</li>
                            <li>Bidder 17 - $0.11 - 4:20:00</li>
                            <li>Bidder 32 - $0.10 - 4:20:00</li>
                            <li>Bidder 32 - $0.09 - 4:20:00</li>
                            <li>Bidder 32 - $0.08 - 4:20:00</li>
                            <li>You - $0.07 - 04:19:53</li>
                        </ul>
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={8}>
                        <h3>Congruatlations, you win!</h3>
                        <p>You were the last bidder and no one outbid you! You will receive an email and the product will show up in your cart.</p>

                    </MDBCol>
                    <MDBCol md={4}>

                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol md={8}>
                        <h3>Donating</h3>
                        <h5>A majority percentage of the bids that you have purchased will go to the non-profit.</h5>
                        <p>To be able to donate to a non-profit, look for the icon in the top right of the auction listing. You can click on the bubble to view
                            information on the non-profit. You can only use bids you have purchased from bid packs. Other bids will not count as donations, 
                            but will still put you in a chance to win the auction. Any eligible bids will cause the Bid button to turn gold! Bids from promo codes
                            are inelgible for donations.
                        </p>

                    </MDBCol>
                    <MDBCol md={4}>
                        <img className="d-hidden" src="https://fast-content.s3.us-west-2.amazonaws.com/images/dbn_part3.png" height="320" />
                    </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                    <MDBCol>
                    <div className='shadow-sm p-3 mb-5 bg-white rounded'>
                        <div style={{textAlign: 'center'}}>
                            {(status != 'hasBids') && (<p>{message} <Button onClick={handleClick} style={{borderRadius:'10px'}} className="btn-other" size="lg">{actionText}</Button></p>)}
                        </div>
                    </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </>
    )
}
export default HowItWorks