import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React, { useState } from 'react'

import AuthService from '../../services/auth.service'
import { Button } from 'react-bootstrap'
import Footer from '../../components/navigation/Footer'
import { useAppState } from '../../contexts/AppStateContext'
import { useNavigate } from 'react-router'

const Verify: React.FC = () => {

    const navigate = useNavigate()
    const [error, setError] = useState('')
    const appState = useAppState()
    const [enabled, setEnabled] = useState(false)
    const [processing, setProcessing] = useState(false)

    const verifyEmail = async () => {
        setProcessing(true)
        try {
            const user = await AuthService.verifyUserActivate()
            alert("An e-mail has been sent")
            setProcessing(false)
            await navHome()

        } catch (e: any) {
            setProcessing(false)
        }
    }

    const navHome = async () => {
        navigate('/home')
    }

    const navDashboard = async () => {
        navigate('/dashboard')
    }

    const navEmail = async () => {
        navigate('/changeemail')
    }

    const navBidderName = async () => {
        navigate('/changeusername')
    }

    return (
        <>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                    <div className="card" style={{borderRadius: 10, width: '25rem', textAlign: 'center'}}>
                        <div className="text-center">
                        <img src="https://www.nabify.com/img/nabifylogo.png" width="175" height="60" />
                        </div>
                        <br />
                        {(!appState.state.verified) ?
                            <p>In order to purchase bids and items from nabify, please verify your email account. A confirmation link will be sent to <Button className="btn-other-sm-tag">{appState.state.email}</Button></p> : 
                            <p>Welcome back to the ultimate giving experience! </p>}
                        {(!processing && !appState.state.verified) ? <><hr /><Button className="btn-other" onClick={verifyEmail}> Send Verification </Button> </>: <></>}

                        {(!appState.state.verified) ? <Button className="btn-other" onClick={navEmail}> Change EMail </Button> : <></>}
                        <hr />
                        {(!appState.state.verified) ? 
                            <Button className="btn-other" style={{fontSize: '1em'}} onClick={navBidderName}> Change Bidder Tag </Button> : 
                            <Button className="btn-other" style={{fontSize: '1em'}} onClick={navHome}> Back to Bidding! </Button>
                        }
                    </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </>
    )
}

export default Verify