import api from "./api";

const getUserProfile = async () => {
  try {
    let result = await api.get("user")
    localStorage.setItem("username",result.data['username'])
    localStorage.setItem("email",result.data['email'])
    localStorage.setItem("verified",result.data["is_verified"])
    return {
      email: result.data['email'], 
      username: result.data['username'], 
      verified: result.data['is_verified'],
      profile: result.data['profile'] 
    }
  } catch (e) {
    throw "User profile fetch error."
  }
}

const getBids = async () => {
    return api.get('bids')
}

const changeUsername = async (username) => {
  return api.post('users/changeusername',{username})
}

const changeEmail = async (email) => {
  return api.post('users/changeemail',{email})
}


const addCartItem = () => {
  return api.post("cartitem")
}

const removeCartItem = () => {
  return api.delete("cartitem")
}

const getInvoices = () => {
  return api.get("invoices")
}

const performCheckout = () => {
  return api.post("checkout")
}

const performCharge = () => {
  return api.post("charge")
}

const performAccountUpgrade = () => {
  return api.post("accounts/upgrade")
}


const UserService = {
  getBids,
  getUserProfile,
  addCartItem,
  removeCartItem,
  getInvoices,
  performCharge,
  performCheckout,
  changeUsername,
  changeEmail,
  performAccountUpgrade
};
export default UserService;