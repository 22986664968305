import { AppStateActions, useAppState } from '../../contexts/AppStateContext';
import {
    MDBCol,
    MDBContainer,
    MDBInputGroup,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBRow,
    MDBSpinner,
    MDBValidation,
    MDBValidationItem
} from 'mdb-react-ui-kit';
import React, { useState } from "react";

import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/navigation/Footer';
import UserService from '../../services/user.service';
import axios from 'axios';
import { useNavigate } from 'react-router';

const ChangeUsername: React.FC<{}> = () => {

    type ChangeUsernameError = {
        message: string,
        status: string
    }

    const navigate = useNavigate()

    const [modalVisible, setModalVisible] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [modalTitle, setTitle] = useState("Title")
    const [modalMessage, setMessage] = useState("Description")
    const appState = useAppState()

    const [formValue, setFormValue] = useState({
        username: '-',
      });

    const onChange = (e: any) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const performUsernameChange = async () => {
        setProcessing(true)
        try {
            await UserService.changeUsername(formValue.username)
            setTitle("Success")
            setMessage("Your username has been updated to " + formValue.username + ".")
            setModalVisible(true)
            appState.dispatch({type: AppStateActions.SetUsername, value: formValue.username})
            setTimeout(() => {
                navigate('/verify')
            }, 2000)

        } catch (err) {
            if (!axios.isAxiosError(err)) {
                alert("Unknown error. Please try again later.")
            } else {
                let error: ChangeUsernameError = err.response?.data
                setTitle("Error")
                setMessage(error.message)
                setModalVisible(true)
                setProcessing(false)
            }
        }

    }
    
    return (
        <>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                    <div className="card card-container" style={{borderRadius: 10}}>
                        <div className="text-center">
                        <img src="https://www.nabify.com/img/nabifylogo.png" width="175" height="60" />
                        </div>
                        <br />
                        <p>If you have signed up through Facebook, you can change your bidder tag here. Your current bidder tag is <Button className="btn-other-sm">{appState.state.username}</Button></p>
                        <ul>
                            <li>Your bidder tag must have at least 5 characters, and no more than 20.</li>
                        </ul>
                        <MDBValidation className='row text-center' isValidated>
                            <MDBValidationItem feedback=''>
                                <MDBInputGroup textBefore='@'>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='validationCustomUsername'
                                    placeholder='Bidder Tag'
                                    onChange={onChange}
                                    required
                                    name='username'
                                    pattern="[A-Za-z0-9 ]{5,20}"
                                />
                                </MDBInputGroup>
                            </MDBValidationItem>
                        </MDBValidation>
                        <br />
                        <ul>
                            <li>Your bidder name can only be updated once! Please use the Contact Us page for additional requests to change your bidder tag.</li>
                            <li>Profanities will be reviewed and may result in the de-activation of your account.</li>
                        </ul>
                        <hr />
                        <br />
                        {processing ? <Button className="btn-other"><MDBSpinner /></Button>: <Button type='submit' className='btn-other' onClick={performUsernameChange} >Change Bid Tag</Button> }
                        {/* {(!processing) ? <Button className="btn-other" onClick={verifyEmail}> Send Verification </Button> : <Button className="btn-other"> <MDBSpinner /></Button>}
                        <hr />
                        {(!processing) ? <Button className="btn-other" onClick={navDashboard}> Change E-Mail </Button> : <Button className="btn-other"> <MDBSpinner /></Button>}
                        <hr />
                        {(!processing) ? <Button className="btn-other" style={{fontSize: '1em'}} onClick={navBidderName}> Change Bidder Name </Button> : <Button className="btn-other"> <MDBSpinner /></Button>} */}
                    </div>
                    </MDBCol>
                </MDBRow>
                
            </MDBContainer>
            <Footer />
            <MDBModal show={modalVisible}>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>{modalTitle}</MDBModalTitle>
                        <Button className='btn-other-sm-tag' color='none' onClick={()=>{setModalVisible(false)}}>X</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        {modalMessage}
                    </MDBModalBody>

                    <MDBModalFooter>
                        <Button className="btn-other" name="close" onClick={()=>{setModalVisible(false)}}>Close</Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
         </>
    );
};
export default ChangeUsername;

