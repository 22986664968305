import "bootstrap/dist/css/bootstrap.min.css";

import {
  Field,
  Form,
  Formik
} from 'formik';
import React, { useState } from "react";
import axios, { AxiosError } from "axios";
import { login, register } from '../../services/auth.service';

import AppService from "../../services/app.service";
import Footer from "../../components/navigation/Footer";
import { useAppState } from "../../contexts/AppStateContext";
import { useNavigate } from "react-router-dom";

const Register: React.FC<{}> = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const navigate = useNavigate()
  const appState = useAppState()
  
  // const validationSchema = Yup.object().shape({
  //   email: Yup.string().required("This field is required!"),
  //   password: Yup.string().required("This field is required!"),
  // });

  
  const handleRegister = async (formValue: { 
      email: string; 
      username: string;
      password: string;
      confirm: string; 
    }) => {

    const { email, username, password, confirm } = formValue;

    setMessage("");
    setLoading(true);

    if (password == confirm) {
      try {
        await register(email, username, password) 
        alert("Welcome to nabify! You will receive an e-mail to verify your account.")
        await login(email, password)
        await AppService.refreshAppState(appState)
        navigate("/dashboard")
      } catch (e: any | AxiosError) {
        if (axios.isAxiosError(e)) {
          console.log(e.response?.data?.errors)
          let errString = ""
          if (e.response?.data?.errors?.email) {
            errString += "Email: " +  e.response?.data?.errors?.email + "\n"
          }
          if (e.response?.data?.errors?.username) {
            errString +="Bidder Tag: " + e.response?.data?.errors?.username + "\n"
          }
          if (e.response?.data?.errors?.password) {
            errString += "Password: " + e.response?.data?.errors?.password + "\n"
          }
          alert(errString)
        } else {
          alert("UNKNOWN ERROR")
        }
        setLoading(false);
      }
    } else {
      alert("Please make sure that the password and confirm password match.")
      setLoading(false);
    }
  };

  let reqView = (
    <ul>
        <li>At least 8 characters</li>
        <li>At least 1 capital letter</li>
        <li>At least 1 number</li>
    </ul>
  )

  interface FormValues {
    email: string;
    username: string;
    password: string;
    confirm: string;
  }
  const initialValues : FormValues = {
    email: "",
    username: "",
    password: "",
    confirm: "",
  };

  const isValidEmail = (email: string) => {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-container">
              <div className="text-center">
                <img src="https://www.nabify.com/img/nabifylogo.png" width="175" height="60" />
              </div>
              <br />
              <p>Your password will need...</p>
              {reqView}
              <Formik
                initialValues={initialValues}
                onSubmit={handleRegister}
              >
                <Form>
                  <div className="form-group">
                    <label htmlFor="email">E-Mail</label>
                    <Field name="email" type="text" className="form-control" maxLength={30}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Bidder Name</label>
                    <Field name="username" type="text" className="form-control" maxLength={15}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field name="password" type="password" className="form-control" maxLength={30}/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Confirm Password</label>
                    <Field name="confirm" type="password" className="form-control" maxLength={30} />
                  </div>
                  <br />
                  <p>*By clicking the Register Account button below, I agree to the <a href="/terms">Terms and Conditions</a></p>
                  <div className="form-group" style={{textAlign: 'center'}}>
                    <br />
                    <button type="submit" className="btn btn-other" disabled={loading}>
                      {loading ?(
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (<span>Register Account</span>)}

                    </button>
                  </div>
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Register;