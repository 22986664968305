import "react-datepicker/dist/react-datepicker.css";
import "../../styles/buttons-special.css"

import { Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { MDBBtn, MDBDropdownDivider, MDBInput, MDBTextArea, MDBTooltip } from 'mdb-react-ui-kit';
import { MDBSelect, MDBSelectOption } from 'mdbreact';
import React, { Component } from 'react';
import { useCallback, useContext, useEffect, useState } from "react";

import Button from "react-bootstrap/esm/Button";
import DatePicker from 'react-datepicker'
import Footer from '../../components/navigation/Footer';
import ListingManageService from '../../services/portal/listing-manage.service';
import ListingService from '../../services/listing.service';
import Product from '../../models/Products';
import ProductManageService from "../../services/portal/product-manage.service";
import TimePicker from 'react-time-picker';
import moment from 'moment';
import { useNavigate } from "react-router";

const PListingEdit: React.FC = () =>  {

    const thisId = new URLSearchParams(window.location.search).get("id");
    const productId = new URLSearchParams(window.location.search).get("pid");

    useEffect(() => {
        (async() => {
            try {
                const result = await ProductManageService.getProductList()
                let pA = result.data.data.map((r: Product) => {
                    return r
                })
                setProductList(pA)
                if (productId) {
                    setSelectedProduct(productId)
                }

                let oldListing = await ListingManageService.getListing(thisId)
                let { data } = oldListing.data
                console.log(data)
                setTitle(data.title)
                setDescription(data.description)
                setFundraisingDescription(data.fundraising_description)
                setListingType(data.type)

                let inputTitle = document.getElementById('ple_title')
                if(inputTitle) (inputTitle as HTMLInputElement).value = data.title

                let inputDesc = document.getElementById('ple_desc')
                if(inputDesc) (inputDesc as HTMLInputElement).value = data.description

                let inputFund = document.getElementById('ple_fund')
                if(inputFund) (inputFund as HTMLInputElement).value = data.fundraising_decription

                
            } catch (e) {


            }
        })()
    },[])

    const navigate = useNavigate()

    const [type, setListingType] = useState('')
    
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [fundraising_description, setFundraisingDescription] = useState('')

    const [starting_bid, setStartingBid] = useState(0)
    const [starting_increment, setIncrement] = useState(0)
    const [starting_time, setStartingTime] = useState('12:00')
    const [starting_date, setStartingDate] = useState(new Date())
    const [starting_unlock, setStartingUnlock] = useState(0)

    const [ending_time, setEndingTime] = useState('12:00')
    const [ending_date, setEndingDate] = useState(new Date())
    const [max_ending_time, setMaxEndingTime] = useState(new Date())

    const [bid_increment, setBidIncrement] = useState(1)

    const [product, setSelectedProduct] = useState<string>()
    const [productList, setProductList] = useState<Product[]>()
    const [productPreviewImage, setProductPreviewImage] = useState<string>('')

    const [status, setStatus] = useState('unstaged')

    const handleTitleChange = (e: any) => {
        setTitle(e.target?.value)
    }

    const handleDescChange = (e: any) => {
        setDescription(e.target?.value)
    }

    const handleFundChange = (e: any) => {
        setFundraisingDescription(e.target?.value)
    }

    const updateSelectedProduct = (e: string) => {
        console.log("FIKND MATCH")
        productList?.forEach((p) => {
            console.log(p.unique_id)
            console.log(e)
            if (p.unique_id == e) {
                setProductPreviewImage(p.image)
            }
        })
        setSelectedProduct(e)
    }

    const submit = async () => {


        let startingDateTime = moment(starting_date)
        let endingDateTime = moment(ending_date)

        let startingTime = moment(starting_time,"HH:mm")
        let endingTime = moment(ending_time,"HH:mm")

        startingDateTime.set({
            hour: startingTime.get('hour'),
            minute: startingTime.get('minute'),
            second: startingTime.get('second')
        })

        endingDateTime.set({
            hour: endingTime.get('hour'),
            minute: endingTime.get('minute'),
            second: endingTime.get('second')
        })

        console.log(startingDateTime.toISOString())
        console.log(endingDateTime.toISOString())
        console.log(product)

        let listing = {
            description,
            fundraising_description,
            title,
            type,
            status,
            starting_bid,
            starting_unlock,
            starting_increment,
            starting_time: startingDateTime.toISOString(),
            starting_date,
            max_ending_time: endingDateTime.toISOString(),
            ending_time,
            ending_date,
            bid_increment,
            product_id: product,
            selected_product: product,
            unique_id: thisId,
            expiry: endingDateTime.toISOString(),
            is_unlockable: false
        }

    
        if (thisId) {
            try {
                // product['unique_id'] = thisId
                await ListingManageService.updateListing(listing)
                alert("Listing updated! You will be redirected back to the portal.")
                navigate('/portal?mode=listings')
            } catch (e) {
                alert("Please check all fields again.")
            }
        } else {
            try {
                await ListingManageService.createListing(listing)
                alert("Listing created! You will be redirected back to the portal.")
                navigate('/portal?mode=listings')
            } catch (e) {
                alert("Please check all fields again.")

            }
        }       
    }

    let listingSettings = (<div></div>)

    if (type == "penny") {
        listingSettings = (
        <div>
            <p className="text-muted">No settings yet for nabify auction.</p>
        </div>
        )   
    }
    else if (type == "sale") {
        listingSettings = (
        <div>
            <p className="text-muted">Sale price goes here. Coming soon!</p>
        </div>
        )   
    }
    else if (type == "silent") {
        listingSettings = (
          <div>
            <div className="form-group">
              <label htmlFor="msrp">Starting Bid</label>
              <p className="text-muted">Set the starting price of the auction.</p>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">$</span>
                <input
                  type="number"
                  className="form-control"
                  name="Starting Bid"
                  value={starting_bid}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="msrp">Bid Increment</label>
              <p className="text-muted">Set the lowest amount that a bidder must bid.</p>
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">$</span>
                <input
                  type="number"
                  className="form-control"
                  name="Bid Increment"
                  value={bid_increment}
                  />
              </div>
            </div>
            <br />
          </div>
        )
      } else if (type == "unlockable") {
        listingSettings = (
          <div>
            <h3>Auction Parameters</h3>
            <p>Number of Bidders It Takes To Unlock Auction</p>
            <input
                type="number"
                className="form-control"
                name="Starting Bid"
                value={starting_unlock}
                />
          </div>
        )
      } else if (type == "unlock-nonstop") {
        listingSettings = (
          <div>
            <h3>Auction Parameters</h3>
            <p>Number of Bidders It Takes To Unlock Auction</p>
            <input
                type="number"
                className="form-control"
                name="Starting Bid"
                value={starting_unlock}
                />
          </div>
        )
    }

    return (
        <>
            <div id="all">
                <div id="content">
                    <div className="container">
                        <div className="col-sm-12">
                            <div id="content">
                                <div className="row">
                                    <div className="col-sm-12 form-group box">
                                    <br />
                                    <br />
                                    <h2>
                                    <div className="numberCircle">1</div>Auction Information</h2>
                                    <p>Select the product you want for your auction.</p>
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td width="40%">
                                                    <Form.Group controlId="formBasicSelect">
                                                        <Form.Label>Select Product</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            className="form-select"
                                                            value={product}
                                                            onChange={e => {
                                                                updateSelectedProduct(e.target.value);
                                                            }}
                                                        >
                                                        {productList?.map((p)=> {
                                                            return (
                                                                <option key={p.unique_id} value={p.unique_id}>
                                                                    {p.name}
                                                                </option>
                                                            )
                                                        })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <br />
                                                    {product && (<img src={productPreviewImage} width="100" height="100" />)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                                    

                                    
                                    <br />
                                    <div className="form-group has-success has-feedback">
                                        <p>Listing Name</p>
                                        <MDBInput placeholder="This is your auction name. By select a product, you can prefill this information in." id="ple_title" onChange={handleTitleChange}/>
                                    </div>
                                    <br />
                                    <div className="form-group has-success has-feedback">
                                        <p>Listing Description</p>
                                        <MDBTextArea placeholder="This is a description for your listing. By selecting a product, you can prefill this information in." id="ple_desc" onChange={handleDescChange} rows={3} />
                                    </div>
                                    <br />
                                    <div className="form-group has-success has-feedback">
                                        <p>Fundraising Description</p>
                                        <MDBTextArea placeholder="Use this section to demonstrate where the funds will go. " id="ple_fund" onChange={handleFundChange} rows={3} />
                                    </div>
                                    <br />
                                    <br />
                                    </div>
                                    <br />
                                    <br />
                                    <div className="col-sm-12 box">
                                        <div className="form-group">
                                            <h2><div className="numberCircle">2</div>Auction Type</h2>
                                            <p className="lead">Please select an auction type.</p>
                                            <hr />
                                            <Button className={` ${type=='penny' ?  'btn-focused btn-other' : 'btn-other' } `} onClick={()=>{setListingType("penny")}}>nabify Auction</Button>
                                            <Button className={` ${type=='silent' ?  'btn-focused btn-other' : 'btn-other' } `} onClick={()=>{setListingType("silent")}}>Silent Auction</Button>
                                            <Button className={` ${type=='sale' ?  'btn-focused btn-other' : 'btn-other' } `} onClick={()=>{setListingType("sale")}}>Buy It Now </Button>
                                            <Button className={` ${type=='unlockable' ?  'btn-focused btn-other' : 'btn-other' } `} onClick={()=>{setListingType("unlockable")}}>Bidder Boosted</Button>
                                            <Button className={` ${type=='unlock-nonstop' ?  'btn-focused btn-other' : 'btn-other' } `} onClick={()=>{setListingType("unlock-nonstop")}}>Bidder Boosted (No Timer)</Button>
                                        </div>
                                    </div>
                                    <div style={{marginTop: '35px'}}>
                                        
                                    </div>
                                    <div className="col-sm-6 box">
                                        <div className="form-group">
                                            <h2><div className="numberCircle">3</div>Auction Settings</h2>
                                            <p className="lead">Please select an auction type.</p>
                                            <hr />
                                            {listingSettings}
                                        </div>
                                    </div>

                                    <div className="col-md-6 form-group box">
                                    <h2><div className="numberCircle">4</div>Pick Ending Date/Time</h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Starting Date/Time</td>
                                                <td><DatePicker selected={starting_date} onChange={(date:Date) => setStartingDate(date)} /></td>
                                                <td><TimePicker onChange={(e)=>{setStartingTime(e.toString())}} value={starting_time}/></td>
                                            </tr>
                                            <tr>
                                                <td>Ending Date/Time</td>
                                                <td><DatePicker selected={ending_date} onChange={(date:Date) => setEndingDate(date)} id="datePickerEnd" /></td>
                                                <td><TimePicker onChange={(e)=>{setEndingTime(e.toString())}} value={ending_time} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <br />
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="row">
                                    
                                    <div className="col-md-4 form-group box">
                                    <h2><div className="numberCircle">5</div>Submit Info</h2>
                                    <p>After the information is set, use the button below to create an listing.</p>

                                    {(thisId !== "") && (<Button className="btn-other" onClick={()=>{submit()}}>Update Listing</Button>)}
                                    {(thisId == "") && (<Button className="btn-other" onClick={()=>{submit()}}>Create Listing</Button>)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <hr />
            <Footer />
        </>
    );

    
}



export default PListingEdit;

