import TokenService from "./token.service";
import axios from "axios";

const prodConfig = {
  connectionURL: "https://api-base.nabify.com/api/",
  projectId: "nabify-webapp"
};

const devConfig = {
  connectionURL: "http://localhost:8000/api/",
  projectId: "nabify-test-dev"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

const instance = axios.create({
  baseURL: config.connectionURL,
  headers: {
    "Content-Type": "application/json",
  },
});

//UTILITY
// const decodeToken = (token) => {
//   try {
//     return JSON.parse(atob(token.split('.')[1]));
//   } catch (e) {
//     return null;
//   }
// }


instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          // const newAxios = axios.create({
          //   baseURL: "http://localhost:8000/api/",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          // })
          const rs = await instance.post("auth/token/refresh", {
            refresh: TokenService.getLocalRefreshToken(),
          });
          TokenService.setUser(rs.data);
          // originalConfig._retry = true;
          return instance(originalConfig);
        } catch (_error) {

          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
export default instance;