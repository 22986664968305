import {
  MDBBadge,
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler
} from "mdb-react-ui-kit";
import { memo, useEffect, useState } from "react";

import AppService from "../../services/app.service";
import AuthService from "../../services/auth.service";
import React from "react";
import { useAppState } from "../../contexts/AppStateContext";
import { useNavigate } from "react-router-dom";

const Navigation: React.FC = () => {

  const [showModal, setShowModal] = useState(false);
  const [showNavColorThird, setShowNavColorThird] = useState(false);

  const navigate = useNavigate();
  const appState = useAppState();

  useEffect(() => {

    (async () => {
      AppService.refreshAppState(appState)
    })()
    
    document.addEventListener("click", toggleModal);
  }, []);

  const navHome = () => {
    navigate("/home");
  };

  const navDashboard = () => {
    if (AuthService.getCurrentUser()) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }

  const navVerify= () => {
    if (AuthService.getCurrentUser()) {
      navigate("/verify");
    } else {
      navigate("/login");
    }
  }

  const navBids = () => {
    navigate('/buybids')
  }

  const logOut = () => {
    AuthService.logout();
    navigate("/login");
  };

  function handleClick() {
    setShowModal(!showModal);
  }

  function toggleModal(e: any) {
    let bellButton = document.getElementById("bell-icon");
    if (
      e.target !== bellButton &&
      e.target.className !== "notifications" &&
      e.target.className !== "group-header-new" &&
      e.target.className !== "group-header-earlier"
    ) {
      setShowModal(false);
    }
  }

  return (
    <>
      <MDBNavbar sticky expand='sm' light style={{ backgroundColor: '#fff' }}>
        <MDBContainer fluid>
          <MDBNavbarBrand>
              <img onClick={navHome} src="https://www.nabify.com/img/nabifylogo.png" height="36" />
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarColor02'
            aria-controls='navbarColor02'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavColorThird(!showNavColorThird)}
          >
            <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColorThird} navbar>
            <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'> 
              <MDBNavbarItem>
                  <MDBNavbarLink onClick={()=> {navigate('/partners')}}>
                    Partners
                  </MDBNavbarLink>
                </MDBNavbarItem>
            </MDBNavbarNav>   
            <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'> 
              <MDBNavbarItem>
                  <MDBNavbarLink onClick={()=> {navigate('/how-it-works')}}>
                    Bidding Guide
                  </MDBNavbarLink>
                </MDBNavbarItem>
            </MDBNavbarNav>             
            <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>  
              <MDBNavbarItem>
                <MDBNavbarLink>
                  {(AuthService.getCurrentUser() && !appState.state.verified) && (
                    <span onClick={navVerify} style={{backgroundColor: "#336699", color: 'white',  padding: '10px 20px', borderRadius: "20px", fontWeight: 800}}>
                      Verify Account
                    </span>)
                  }
                </MDBNavbarLink>
              </MDBNavbarItem>     
              <MDBNavbarItem>
                <MDBNavbarLink>
                  {(AuthService.getCurrentUser()) && (<span onClick={navDashboard} style={{backgroundColor: "#131f59", color: 'white',  padding: '10px 20px', borderRadius: "20px", fontWeight: 800}}>
                    {appState.state.username}
                  </span>)}
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}}>
                    <img src="https://nabify.com/img/nabify_24.png" onClick={navBids} width="24" height="24"></img>
                    {
                      (appState.state.bids && <span className="badge-alt" onClick={navBids}>    {appState.state.bids}</span>)
                    }
                  </span>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}}>
                    <MDBIcon color='dark'fas icon='bell'></MDBIcon>
                    {/* {
                      (appState.localNotifications.length > 0) && <MDBBadge pill color='danger'>5</MDBBadge>})
                    } */}
                  </span>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <span style={{backgroundColor: "#EEE", padding: '10px', borderRadius: "20px"}} onClick={navDashboard}>
                    <MDBIcon color='dark' fas icon='shopping-bag'></MDBIcon>
                    {
                      (appState.state.cart?.items && appState.state.cart?.items.length > 0 ? (<span onClick={navDashboard} className="badge-alt">{appState.state.cart.items.length}</span>) : (<></>))
                    }
                  </span>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};
export default memo(Navigation);
