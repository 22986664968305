import "../../styles/buttons-special.css";
import './homelisting.css';

import { AppStateActions, useAppState } from '../../contexts/AppStateContext';
import { MDBIcon, MDBSpinner } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

import AuthService from "../../services/auth.service";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CountDownTimer from '../countdown/CountDownTimer';
import Listing from '../../models/Listing';
import ListingService from '../../services/listing.service';
import UserService from "../../services/user.service";
import moment from 'moment';
import { useNavigate } from 'react-router';

const HomeListing: React.FC = () =>  {

    const appState = useAppState()
    const navigate = useNavigate()

    const [listingCanClickOverride, setListingCanClickOverride] = useState(new Set())

    useEffect(() => {
        (async () => {
            try {
                let { data } = await ListingService.getLiveListings();
                let listings : Listing[] = data.data
                appState.dispatch({type: AppStateActions.UpdateAllListings, value: listings})
            } catch (e) {

            }
        })()
    }, [])

    const navigateToListing = (unique_id: string) => {
        navigate('/a/'+unique_id)
    }

    const bidOnListing = async(unique_id: string) => {
        setListingCanClickOverride(prev => new Set(prev.add(unique_id)))
        try {
            await ListingService.bidOnListing(unique_id)
            setTimeout(async () => {
                setListingCanClickOverride(prev => new Set(Array.from(prev).filter(x => x !== unique_id)))
                let res = await UserService.getBids()
                appState.dispatch({type: AppStateActions.SetBids, value: res.data.bids})
            }, 1000)
        } catch (e) {
            setListingCanClickOverride(prev => new Set(Array.from(prev).filter(x => x !== unique_id)))
            console.log(e)
        }
    
    }

    return (
        <>
            <div className='shadow-sm p-3 mb-5 bg-white rounded'>
                <div className="list-prev-container">
                {
                    appState.state.orderedListings.map((listing) => {
                        return (
                            <Card key={listing.unique_id} className="list-prev-card">
                                <Card.Img onClick={() => {navigateToListing(listing.unique_id)}} variant="top" className="list-prev-image" src={listing.product.image} />
                                <Card.Body>
                                    <Card.Title onClick={() => {navigateToListing(listing.unique_id)}}><p className="lineClamp list-prev-title">{listing.title}</p></Card.Title>
                                    <hr />
                                    <Card.Title>
                                        {/* //NOTE: perform clamp */}
                                        <p className="text-center list-prev-msrp">MSRP: <span >${listing.product.msrp}</span></p>
                                        <p className="text-center list-prev-c">Current Bid: <span style={{paddingLeft: 5}}></span>
                                            {!(listingCanClickOverride.has(listing.unique_id)) && <span className="animated fadeIn list-prev-c-badge">${(listing.currency / 100).toFixed(2)}</span>}
                                            {(listingCanClickOverride.has(listing.unique_id)) && <span className="animated list-prev-c-badge">${(listing.currency / 100).toFixed(2)}</span>}
                                        </p>
                                    </Card.Title>
                                    <Card.Title>
                                        <CountDownTimer targetDate={new Date(moment(listing.expiry).toDate())}/>
                                    </Card.Title>
                                    <br />
                                    <Card.Title>
                                        {
                                            (!listing.is_closed) 
                                            ? 
                                                (
                                                    <>
                                                    {(listing.bidder) && (<p style={{minHeight: '2.3em'}} className={`text-center list-prev-bidder ${listing.bidder?.username == appState.state?.username  ? 'text-sea-green' : ''}`}><MDBIcon fas icon="user"/><span >{'  '}{listing.bidder?.username} is winning!</span></p>)}
                                                    {(!listing.bidder) && (<p style={{minHeight: '2.3em'}} className="text-center list-prev-bidder"><MDBIcon fas icon="user"/><span >{'  '}Be the first to bid!</span></p>)}
                                                    </>
                                                )

                                            :
                                                (
                                                    <>
                                                    {(listing.bidder) && (<p style={{minHeight: '2.3em'}} className={`text-center list-prev-bidder ${listing.bidder?.username == appState.state?.username  ? 'text-sea-green' : ''}`}><MDBIcon fas icon="user"/><span >{'  '}{listing.bidder?.username} has won!</span></p>)}
                                                    {(!listing.bidder) && (<p style={{minHeight: '2.3em'}} className="text-center list-prev-bidder"><MDBIcon fas icon="user"/><span >{'  '}Be the first to bid!</span></p>)}
                                                    </>
                                                )
                                        }
                                    </Card.Title>
                                    <hr />
                                    <div className="text-center">
                                        {       
                                            AuthService?.getCurrentUser() && (
                                                (!listing.is_closed) ? 
                                                    (
                                                        <>
                                                            {!(listingCanClickOverride.has(listing.unique_id)) && (<Button className="btn-other btn-other-compact" onClick={()=>{bidOnListing(listing.unique_id)}}>Bid 1<img src="https://nabify.com/img/nabify_24.png" height="24" width="24"/></Button>)}
                                                            {(listingCanClickOverride.has(listing.unique_id)) && (<Button className="btn-other-compact"><MDBSpinner/></Button>)}
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <Button className="btn-other-compact" disabled>Auction Ended</Button>
                                                        </>
                                                    )
                                            )
                                        }
                                        {
                                            !AuthService?.getCurrentUser() && (
                                                (!listing.is_closed) ? 
                                                    (
                                                        <>
                                                            <Button variant="btn-other" onClick={()=>{navigate('/buybids')}}>Sign In To Bid!</Button>
                                                        </>
                                                    ):
                                                    (
                                                        <>
                                                            <Button className="btn-other-compact" disabled>Auction Ended</Button>
                                                        </>
                                                    )

                                            )

                                        }     
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
                </div>
            </div>
        </>
    );
}

export default HomeListing;


